<script lang="ts">
    import { Form, FormText, Button, Input, FormGroup } from "@wts/sveltestrap";
    import WtsLogo from "@components/WTSLogo.svelte";
    import type { Login } from "@wts/types/view-models/login";
    import { login } from "@apis/login-api";
    import FlexBox from "@components/FlexBox.svelte";
    import VerifyCode from "./VerifyCode.svelte";
    import type { VerifyCode as VerifyCodeModel } from "@wts/types/view-models/verify-code";
    import * as toastr from "@stores/toasts";
    import { onMount } from "svelte";

    export let Model: Login;
    export let AntiForgeryToken: string;
    export let DateTime: number;

    let emailRef: HTMLInputElement | undefined;
    let isValid: boolean = false;
    let isDirty: boolean = false;
    let submitting: boolean = false;
    let errorMessage: String | null;
    let form: Form;
    let formData: Login = {
        EmailAddress: Model.EmailAddress,
        Password: Model.Password,
        ReturnUrl: Model.ReturnUrl,
        RememberMe: Model.RememberMe,
    };
    let showVerifyCodeView = false;

    /**
     * For VerifyCode props.
     */
    let verifyCodeModel: VerifyCodeModel | null;

    /**
     * Possible non error status code failed login attempts returned from controller in res.
     */
    let failedLoginResponses: string[] = [
        "Email address not found.",
        "User does not have access.",
        "This account has been locked. Please try again later.",
        "Invalid email address or password.",
    ];

    /**
     * Sends form data payload with __RequestVerificationToken to login route.
     *
     * @async
     * @return {Promise<void>}
     */
    async function onSubmit(): Promise<void> {
        submitting = true;

        const [err, res, messages] = await login(formData, {
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: (<HTMLInputElement>document.getElementsByName("__RequestVerificationToken")[0]).value,
            },
        });

        if (err) {
            if (err instanceof Error && err.message === "Redirecting") {
                // do nothing, as the page will redirect shortly
                return;
            }

            let coercedError: string;
            if (res === null) {
                coercedError = "An error occured on our end";
            } else {
                coercedError = `${res}`;
            }
            toastr.error(coercedError);
            errorMessage = coercedError;
            showVerifyCodeView = false;
        } else if (failedLoginResponses.indexOf(String(res)) > -1) {
            errorMessage = String(res);
        } else {
            verifyCodeModel = res;
            if (verifyCodeModel.VerifyCodeID != null) {
                showVerifyCodeView = true;
            }
        }

        submitting = false;
    }

    onMount(() => {
        emailRef?.focus();
    });
</script>

{#if !showVerifyCodeView}
    <div class="verify-code-container">
        <div class="logo">
            <WtsLogo width="100%" height="100%" color="#fff" background="var(--bs-gray-dark)" />
        </div>
        <div class="verify-code-form card">
            <Form
                id="login-form"
                useValidator={true}
                bind:this={form}
                bind:isValid
                bind:isDirty
                bind:data={formData}
                on:submit={() => onSubmit()}>
                <FormGroup class="mb-1">
                    {@html AntiForgeryToken}
                    <input type="hidden" name="ReturnUrl" value={Model.ReturnUrl} />
                    <Input
                        bind:inner={emailRef}
                        isRequired={true}
                        isEmail={true}
                        type="email"
                        placeholder="Email"
                        id="EmailAdress"
                        name="EmailAddress" />
                </FormGroup>
                <FormGroup class="mb-1">
                    <Input isRequired={true} type="password" placeholder="Password" id="Password" name="Password" />
                </FormGroup>
                <FlexBox class="mt-4 d-flex justify-content-end">
                    <Button isLoading={submitting} type="submit" form="login-form" color="primary" disabled={!isValid}>Login</Button>
                </FlexBox>
            </Form>
            {#if errorMessage != "Redirecting" && errorMessage}
                <div>
                    <FormText color="danger">{errorMessage}</FormText>
                </div>
            {/if}
        </div>
    </div>
{/if}

{#if showVerifyCodeView}
    <VerifyCode
        ReturnUrl={verifyCodeModel?.ReturnUrl}
        RememberMe={verifyCodeModel?.RememberMe}
        VerifyCodeID={verifyCodeModel?.VerifyCodeID}
        ErrorMessage={verifyCodeModel?.ErrorMessage}
        Code={verifyCodeModel?.Code}
        NewCode={verifyCodeModel?.NewCode} />
{/if}

<div class="small text-center text-muted position-fixed bottom-0 w-100 p-3 font-monospace">
    {DateTime} &copy; Work Truck Solutions
</div>

<style lang="less">
    .verify-code-container {
        width: 420px;
        max-width: 100%;
        margin: 7% auto;
    }

    .logo {
        width: 80%;
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 3rem;
    }

    .verify-code-form {
        background: var(--bs-body-bg);
        padding: 2rem;
        border-top: 0;
    }

    // hide number input spinner
    :global(input::-webkit-outer-spin-button),
    :global(input::-webkit-inner-spin-button) {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    :global(input[type="number"]) {
        -moz-appearance: textfield;
        appearance: textfield;
    }
</style>
